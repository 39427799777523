<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Almacen - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
               
     
                <b-col md="2">
                  <b-form-group label="Código:">
                    <b-form-input type="text" v-model="warehouse.code"></b-form-input>
                    <small v-if="errors.code" class="form-text text-danger">Ingrese un código</small>
                  </b-form-group>
                </b-col>
       
                <b-col md="6">
                  <b-form-group label="Nombre:">
                    <b-form-input type="text" v-model="warehouse.name"></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger">Ingrese un nombre</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Nombre Corto:">
                    <b-form-input type="text" v-model="warehouse.short_name"></b-form-input>
                    <small v-if="errors.short_name" class="form-text text-danger">Ingrese un nombre corto</small>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Descripción:">
                    <b-form-input type="text" v-model="warehouse.description"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="por defecto ?:">
                    <b-form-select v-model="warehouse.default" :options="default_warehouse"></b-form-select>
                  </b-form-group>
                </b-col>
         
                <b-col md="2">
                  <b-form-group label="Estado :">
                    <b-form-select v-model="warehouse.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "VoucherAdd",
  data() {
    return {
      module:'Warehouse',
      role:2,
      warehouse: {
        code: "",
        name: "",
        short_name: "",
        description: "",
        default: 1,
        state: 1,
      },
      default_warehouse:[
        {value: 1 , text : 'Si'},
        {value: 0 , text : 'No'},
      ],
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        code: false,
        name: false,
        short_name: false,
      },
      validate: false,
    };
  },
  mounted() {
  
  },
  methods: {
    AddWarehouse,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function AddWarehouse() {

  let me = this;
  let url = me.url_base + "warehouse/add";
  let data =  me.warehouse;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: this.token,  module: this.module, role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.warehouse.code = "";
        me.warehouse.name = "";
        me.warehouse.short_name = "";
        me.warehouse.description = "";
        me.warehouse.default = 1;
        me.warehouse.state = 1;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
 
  this.errors.code = this.warehouse.code.length == 0 ? true : false;
  this.errors.name = this.warehouse.name.length == 0 ? true : false;
  this.errors.short_name = this.warehouse.short_name.length == 0 ? true : false;

  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.short_name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de registrar el almacen?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddWarehouse();
      }
    });

  }

}
</script>
